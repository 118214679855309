<template>
  <div class="client-requests">
    <div v-if="!isEmptyList">
      <div class="client-requests__buttons">
        <b-button
          variant="primary"
          class="client-requests__button client-requests__button_create"
          @click="showRequestCreateModal"
        >
          <i class="icon-local-plus" />
        </b-button>
        <b-button
          variant="secondary"
          class="client-requests__button"
          v-b-toggle.filters
        >
          <!-- <i class="icon-local-search" /> -->
          {{ $t('button.filter') }}
        </b-button>
      </div>

      <b-collapse id="filters" :visible="isVisibleFilters" role="tabpanel">
        <div class="portlet filters mt-3">
          <div class="title mb-3">
            {{ $t('button.filter') }}
          </div>
          <b-form-row class="filters-row">
            <b-col md="3">
              <statuses-autocomplete
                v-model="filters.status"
                :placeholder="$t('client_request.filters.status')"
                :items="statuses"
                objectProperty="id"
                display="title"
                @select="updateParams"
              />
            </b-col>
            <b-col md="3">
              <date-picker
                class="w-100 d-block"
                v-model="filters.created_at"
                :label="$t('client_request.filters.create_at')"
                range
                value-type="format"
                format="YYYY-MM-DD"
                @input="updateDate"
                :disabled-date="notAfterToday"
                :default-value="new Date()"
              ></date-picker>
            </b-col>
          </b-form-row>
        </div>
      </b-collapse>

      <h2 class="client-requests__title">
        {{ $t('client_request.manage_list') }}
      </h2>

      <div
        v-if="requests && requests.length > 0"
        class="client-requests__list mb-3"
      >
        <client-request
          v-for="request in requests"
          :key="request.id"
          :request="request"
          @openModal="showRequestModal(request)"
        ></client-request>
      </div>

      <div v-else>
        {{ $t('global.not_found') }}
      </div>

      <div v-if="pagination.pages > 1" class="client-requests__pagination">
        <b-pagination
          @change="onChangePage"
          v-model="pagination.current"
          :total-rows="pagination.count"
          :per-page="perPage"
          aria-controls="my-table"
          align="left"
        />
      </div>
    </div>

    <div v-else-if="!loading" class="client-requests__empty">
      <img
        src="/img/illustrations/new_empty_buildings.svg"
        class="client-requests__img"
      />
      <p class="client-requests__title client-requests__title_empty">
        {{ $t('client_request.hello') }} {{ platformName }}
      </p>
      <p class="client-requests__text">{{ $t('client_request.empty') }}</p>
      <b-button
        variant="primary"
        class="client-requests__create"
        size="lg"
        @click="showRequestCreateModal"
      >
        {{ $t('client_request.create') }}
      </b-button>
    </div>

    <div v-else class="client-requests__empty">
      <b-spinner
        style="width: 3rem; height: 3rem"
        class="client-requests__spinner"
      />
    </div>

    <client-request-create-modal
      ref="clientRequestCreateModal"
      @create="getRequests"
    />

    <client-request-edit-modal
      ref="clientRequestEditModal"
      @updateRequest="getRequests"
    />
  </div>
</template>

<script>
import qs from 'qs';
import bridge from '@/helpers/bridge';
import { API_URLS } from '@/consts';
import { getManageId } from '@/helpers';
import { request3 } from '@/api/request';
import { BPagination } from 'bootstrap-vue';
import DatePicker from '@app/components/base/DatePicker';
import ClientRequest from './ClientRequest.vue';
import ClientRequestEditModal from './ClientRequestEditModal.vue';
import ClientRequestCreateModal from './ClientRequestCreateModal.vue';
import StatusesAutocomplete from '@/components/autocompletes/StatusesAutocomplete';

export default {
  name: 'ManageClientRequests',
  props: { platform_name: String },
  components: {
    DatePicker,
    BPagination,
    ClientRequest,
    StatusesAutocomplete,
    ClientRequestEditModal,
    ClientRequestCreateModal,
    StatusesAutocomplete,
  },
  data() {
    return {
      requests: null,
      pagination: {
        count: 0,
        current: 1,
        pages: 1,
        next: 1,
      },
      perPage: 20,
      page: 1,
      filters: {
        status: null,
        created_at: null,
      },
      isVisibleFilters: false,
      loading: false,
    };
  },
  computed: {
    platformName() {
      return bridge.appData.platform_name;
    },

    isEmptyList() {
      if (!this.requests) {
        return true;
      } else {
        this.requests.length == 0 && this.isEmptyFilters ? true : false;
      }
    },

    isEmptyFilters() {
      if (this.filters) {
        return this.filters.status || this.filters.created_at ? false : true;
      }
      return true;
    },

    statuses() {
      return [
        {
          id: '3',
          title: this.$t('client_request.statuses.denied'),
        },
        {
          id: '1',
          title: this.$t('client_request.statuses.in_progress'),
        },
        {
          id: '0',
          title: this.$t('client_request.statuses.new'),
        },
        {
          id: '2',
          title: this.$t('client_request.statuses.accept'),
        },
      ];
    },

    queryParams() {
      return qs.stringify(
        {
          q: { ...this.filters },
        },
        {
          arrayFormat: 'brackets',
          encode: false,
        },
      );
    },
  },

  created() {
    if (this.platform_name) {
      bridge.appData.platform_name =
        this.platform_name.charAt(0).toUpperCase() +
        this.platform_name.slice(1);
    }

    this.getRequests();
  },

  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    if (id) {
      await request3
        .get(API_URLS.backendManage.client_requests.one(id, this.getManageId()))
        .then((response) =>
          this.$nextTick(() => this.showRequestModal(response.data)),
        )
        .catch((err) => this.$bvToast.toast(this.$t('client_request.error')));
    }

    const parsed = qs.parse(window.location.search.substring(1));
    this.filters = Object.assign({}, parsed.q);

    this.isVisibleFilters = !this.isEmptyFilters;
  },

  methods: {
    getManageId,

    notAfterToday(date) {
      return date > new Date();
    },

    onChangePage(page) {
      if (page === this.pagination.current) return;

      this.page = page;
      this.getRequests();
    },

    updateDate(date) {
      if (date[0] && date[1]) {
        this.filters = Object.assign(this.filters, { created_at: date });
      } else {
        this.filters = Object.assign(this.filters, { created_at: null });
      }

      this.updateParams();
    },

    updateParams() {
      const q = this.queryParams;

      window.history.replaceState(null, null, `?${q}`);
      this.page = 1;

      this.getRequests();
    },

    getRequests() {
      this.loading = true;
      request3
        .get(API_URLS.backendManage.client_requests.fetch(), {
          params: {
            per_page: this.perPage,
            page: this.page,
            q: {
              status_eq: this.filters.status,
              created_at_gt: this.filters.created_at
                ? this.createdAtFilterValue(this.filters.created_at[0], 'gt')
                : null,
              created_at_lt: this.filters.created_at
                ? this.createdAtFilterValue(this.filters.created_at[1], 'lt')
                : null,
            },
          },
        })
        .then((response) => {
          this.requests = response.data;
          this.pagination = JSON.parse(response.headers['x-pagination'] || {});
        })
        .catch(() => this.$bvToast.toast(this.$t('client_request.error')))
        .finally(() => (this.loading = false));
    },

    showRequestModal(request) {
      this.$refs.clientRequestEditModal.show(request);
    },

    showRequestCreateModal() {
      this.$refs.clientRequestCreateModal.show();
    },

    createdAtFilterValue(value, filter) {
      if (value === null) { return }

      let filterValue = new Date(value)
      switch(filter) {
        case 'lt':
          filterValue.setHours(23, 59, 59)
          break
        case 'gt':
          filterValue.setHours(0, 0, 0)
          break
      }
      return filterValue.toISOString();
    },
  },
};
</script>

<style lang="scss"></style>
