<template>
  <div>
    <b-modal
      id="modal"
      ref="modal"
      footer-class="pt-0"
      centered
      modal-class="modal-request"
      size="lg"
    >
      <template #modal-header="{ close }">
        <client-request-modal-header :request="request" />

        <button class="close" @click="close()">
          <i class="icon-local-times"></i>
        </button>
      </template>

      <div class="modal-body-row with-cols pt-30" :key="request.id">
        <client-request-info :request="request" is-edit />
        <b-form-validate ref="form" :validations="validations" :model="request">
          <template slot-scope="{ validateState, v }">
            <div class="title">
              {{ $t('client_request.service_data.title') }} {{ platformName }}
            </div>

            <b-form-row v-if="request.status != 'denied'">
              <b-col cols="6">
                <b-input-label
                  :label="$t('client_request.service_data.manager')"
                  :value="managerRequest"
                  disabled
                />
              </b-col>

              <b-col cols="6">
                <tariffs-autocomplete
                  v-model="request.service_plan_id"
                  :placeholder="$t('client_request.service_data.tariff')"
                  :disabled="!canEdit"
                  :items="service_plans"
                  objectProperty="id"
                  display="full_name"
                  :state="validateState('service_plan_id')"
                  @clear="clearTariff"
                />
              </b-col>

              <b-col cols="6">
                <date-picker
                  class="w-100 d-block"
                  v-model="request.active_from"
                  :label="$t('client_request.service_data.tariff_first_date')"
                  format="YYYY-MM-D"
                  :is-invalid="getValideDate"
                  :disabled="disabledDatePicker"
                  @input="handleInputActiveFrom"
                ></date-picker>
                <b-error
                  v-if="!v.model.active_from.required && isSubmit"
                  :error="$t('devices.errors.not_empty')"
                />
              </b-col>

              <b-col cols="6">
                <date-picker
                  class="w-100 d-block"
                  :label="$t('client_request.service_data.tariff_last_date')"
                  v-model="request.active_to"
                  format="YYYY-MM-D"
                  :disabled="disabledDatePicker || !request.active_from"
                  :disabled-date="notAfterToFrom"
                ></date-picker>
              </b-col>
            </b-form-row>

            <div v-else>
              <b-form-row>
                <b-col cols="6">
                  <b-input-label
                    :label="$t('client_request.service_data.manager')"
                    :value="managerRequest"
                    disabled
                  />
                </b-col>
                <b-col cols="6">
                  <b-input-label
                    :label="$t('client_request.manager_data.email')"
                    v-model="request.manager.email"
                    disabled
                  ></b-input-label>
                </b-col>
                <b-col cols="6">
                  <b-input-label
                    :label="$t('client_request.manager_data.phone')"
                    v-model="request.manager.phone"
                    disabled
                  ></b-input-label>
                </b-col>
              </b-form-row>
              <div class="title">
                {{ $t('client_request.service_data.reason_denied') }}
              </div>
              <b-form-row>
                <b-col cols="12">
                  <b-input-label
                    :label="$t('client_request.service_data.comment')"
                    :value="request.comment"
                    disabled
                  />
                </b-col>
              </b-form-row>
            </div>
          </template>
        </b-form-validate>
      </div>

      <template #modal-footer>
        <div v-if="request.status == 'new'">
          <b-button variant="primary" @click="requestToProgress">
            {{ $t('client_request.modal.take_moderation') }}
          </b-button>
        </div>

        <div v-if="canEdit" class="d-flex gap-2 w-100">
          <b-button
            variant="secondary"
            @click="openCancelModeration"
            class="mr-auto"
          >
            {{ $t('client_request.modal.cancel_moderation') }}
          </b-button>

          <b-button variant="danger" @click="openCommentModal">
            {{ $t('client_request.modal.denied') }}
          </b-button>
          <b-button variant="primary" @click="openAcceptModal">
            {{ $t('client_request.modal.accept') }}
          </b-button>
        </div>

        <div
          v-if="!canEdit && request.status != 'new'"
          class="text-secondary small"
        >
          {{ statusAt }}
        </div>
      </template>
    </b-modal>
    <client-request-comment-modal
      ref="clientRequestCommentModal"
      @requestDenied="requestDenied"
    />
    <client-request-cancel-moderation-modal
      ref="clientRequestCancelModerationModal"
      @cancelModeration="cancelModeration"
    />
    <client-request-accept-modal
      ref="clientRequestAcceptModal"
      @requestAccept="requestAccept"
    />
  </div>
</template>

<script>
import bridge from '@/helpers/bridge';
import dayjs from 'dayjs';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';
import { getManageId } from '@/helpers';
import { requiredIf } from 'vuelidate/lib/validators';
import ClientRequestInfo from './ClientRequestInfo';
import ClientRequestModalHeader from './ClientRequestModalHeader';
import ClientRequestAcceptModal from './ClientRequestAcceptModal';
import ClientRequestCommentModal from './ClientRequestCommentModal';
import ClientRequestCancelModerationModal from './ClientRequestCancelModerationModal';
import BError from '@/components/base/BError';
import DatePicker from '@app/components/base/DatePicker';
import TariffsAutocomplete from '@/components/autocompletes/TariffsAutocomplete';

export default {
  name: 'ClientRequestEditModal',
  components: {
    BError,
    DatePicker,
    ClientRequestInfo,
    TariffsAutocomplete,
    ClientRequestModalHeader,
    ClientRequestAcceptModal,
    ClientRequestCommentModal,
    ClientRequestCancelModerationModal,
  },
  data() {
    return {
      request: {},
      service_plans: [],
      clientId: this.getManageId(),
      isSubmit: false,
    };
  },

  computed: {
    platformName() {
      return bridge.appData.platform_name;
    },

    validations() {
      return {
        active_from: {
          required: requiredIf(() => this.request.service_plan_id),
        },
      };
    },

    getValideDate() {
      return this.isSubmit
        ? Boolean(!this.request.active_from && this.request.service_plan_id)
        : undefined;
    },

    managerRequest() {
      let val = '';
      if (this.request.manager) {
        val += this.request.manager.surname
          ? this.request.manager.surname + ' '
          : '';

        val += this.request.manager.name ? this.request.manager.name + ' ' : '';

        val += this.request.manager.patronymic
          ? this.request.manager.patronymic
          : '';
      }

      return val;
    },

    statusAt() {
      return dayjs(this.request.status_at).format('L, LT');
    },

    user() {
      return bridge.user;
    },

    canEdit() {
      return (
        this.request.status == 'in_progress' &&
        this.request.manager &&
        this.request.manager.id == this.user.id
      );
    },

    disabledDatePicker() {
      if (!this.canEdit) {
        return true;
      } else if (!this.request.service_plan_id) {
        return true;
      }
      return false;
    },
  },

  created() {
    request3
      .get(API_URLS.backendManage.autocomplete.service_plans.fetch())
      .then((res) => (this.service_plans = res.data));
  },

  methods: {
    getManageId,

    show(request) {
      this.isSubmit = false;
      this.request = Object.assign(
        {
          service_plan_id: null,
          active_from: null,
          active_to: null,
        },
        request,
      );
      this.$refs.modal.show();
    },

    notAfterToFrom(date) {
      return date <= new Date(this.request.active_from);
    },

    requestToProgress() {
      let params = {
        id: this.request.id,
        status: 'in_progress',
      };
      this.updateRequest(params);
    },

    openCommentModal() {
      this.isSubmit = true;
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$refs.clientRequestCommentModal.show();
    },

    openCancelModeration() {
      this.$refs.clientRequestCancelModerationModal.show();
    },

    openAcceptModal() {
      this.isSubmit = true;
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$refs.clientRequestAcceptModal.show();
    },

    requestDenied(comment) {
      let params = {
        id: this.request.id,
        status: 'denied',
        comment: comment,
      };
      this.updateRequest(params);
    },

    cancelModeration() {
      let params = {
        id: this.request.id,
        status: 'new',
      };
      this.updateRequest(params);
    },

    requestAccept() {
      let params = {
        id: this.request.id,
        status: 'accepted',
        service_plan_id: this.request.service_plan_id,
        active_from: dayjs(this.request.active_from).format('YYYY-MM-DD'),
        active_to: dayjs(this.request.active_to).format('YYYY-MM-DD'),
      };

      request3
        .post(
          API_URLS.backendManage.client_requests.accept(
            this.request.id,
            this.clientId,
          ),
          {
            client_request: {
              ...params,
            },
          },
        )
        .then((res) => {
          this.request = Object.assign({}, res.data);
          this.$emit('updateRequest');
        })
        .catch(() => this.$bvToast.toast(this.$t('client_request.error')));
    },

    updateRequest(params) {
      request3
        .put(
          API_URLS.backendManage.client_requests.one(
            this.request.id,
            this.clientId,
          ),
          {
            client_request: {
              ...params,
            },
          },
        )
        .then((res) => {
          this.request = Object.assign({}, res.data);
          this.$emit('updateRequest');
        })
        .catch(() => this.$bvToast.toast(this.$t('client_request.error')));
    },

    clearTariff() {
      this.request = Object.assign(this.request, {
        service_plan_id: null,
        active_from: null,
        active_to: null,
      });
    },
    handleInputActiveFrom(val) {
      if (val >= this.request.active_to) {
        this.request.active_to = null;
      }
    },
  },
};
</script>

<style></style>
