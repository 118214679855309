<template>
  <div>
    <b-modal
      ref="createModal"
      modal-class="modal-request"
      footer-class="pt-0"
      header-class="modal-request__head modal-request__head_create pb-0"
      centered
      @hidden="hide"
    >
      <template #modal-header="{ close }">
        <div class="title modal-request__title">
          {{ $t('client_request.registration.title') }}
        </div>
        <button class="close" @click="close()">
          <i class="icon-local-times"></i>
        </button>
      </template>

      <b-form-validate
        ref="form"
        :validations="validations"
        :model="client_request"
      >
        <template slot-scope="{ validateState, v }">
          <div class="modal-body-row with-cols">
            <p class="modal-request__info mb-3">
              {{ $t('client_request.registration.info') }}
            </p>
            <div class="title">
              {{ $t('client_request.company_data.title') }}
            </div>

            <b-col class="mb-3">
              <b-input-label
                :label="$t('client_request.company_data.name')"
                v-model="client_request.name"
                :state="validateState('name')"
                class="my-0"
              />
              <b-error
                v-if="!v.model.name.required && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
              <b-error
                v-if="!v.model.name.maxLength && isSubmit"
                :error="$t('devices.errors.max_length', [100])"
              />
              <b-error :error="getBackendErrors('name')" />
            </b-col>
            <b-col class="mb-3">
              <b-input-label
                :label="$t('client_request.company_data.inn')"
                v-model="client_request.inn"
                :state="validateState('inn')"
                class="my-0"
              />
              <b-error
                v-if="!v.model.inn.required && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
              <b-error
                v-if="
                  isSubmit &&
                  client_request.inn &&
                  client_request.inn.length != 10 &&
                  client_request.inn.length != 12
                "
                :error="$t('devices.errors.formatter')"
              />

              <b-error :error="getBackendErrors('inn')" />
            </b-col>
            <b-col class="mb-3">
              <b-input-file
                accept="image/*"
                v-model="client_request.request_file"
                :label="$t('client_request.logo')"
                type="file"
                :state="validateState('request_file')"
                class="my-0"
              ></b-input-file>

              <b-error
                v-if="!v.model.request_file.required && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
              <b-error :error="getBackendErrors('request_file')" />
            </b-col>
          </div>
        </template>
      </b-form-validate>

      <template #modal-footer>
        <b-button
          variant="primary"
          class="client-request__create w-100"
          size="lg"
          @click="create"
        >
          {{ $t('client_request.create') }}
        </b-button>
      </template>
    </b-modal>
    <client-request-success-create-modal ref="successCreateModal" />
  </div>
</template>

<script>
import { serialize } from 'object-to-formdata';
import BInputFile from '@/components/base/BInputFile';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';
import { required, maxLength } from 'vuelidate/lib/validators';
import ClientRequestSuccessCreateModal from './ClientRequestSuccessCreateModal.vue';
import BError from '@/components/base/BError';

export default {
  name: 'ClientRequestCreateModal',
  components: {
    BError,
    BInputFile,
    ClientRequestSuccessCreateModal,
  },
  data() {
    return {
      client_request: {
        name: '',
        inn: '',
        request_file: null,
      },
      backendErrors: [],
      isSubmit: false,
    };
  },
  computed: {
    validations() {
      return {
        name: {
          required,
          maxLength: maxLength(100),
          isValid: () => {
            if (this.getBackendErrors('name')) {
              return false;
            }
            return true;
          },
        },
        inn: {
          required,
          isValid: (value) => {
            if (this.getBackendErrors('inn')) {
              return false;
            }
            return value.length == 10 || value.length == 12;
          },
        },
        request_file: {
          required,
          isValid: () => {
            if (this.getBackendErrors('request_file')) {
              return false;
            }
            return true;
          },
        },
      };
    },
  },
  methods: {
    show() {
      this.isSubmit = false;

      this.client_request = Object.assign({
        name: '',
        inn: '',
        request_file: null,
      });

      this.$refs.createModal.show();
    },

    // сериализует параметры (для того, чтобы корректно отправилась картинка)
    serializeParams(data) {
      return serialize(data, null, null, 'client_request');
    },

    create() {
      this.isSubmit = true;
      this.backendErrors = [];

      if (!this.$refs.form.validate()) {
        return;
      }
      request3
        .post(
          API_URLS.backendPersonal.client_requests.fetch(),
          this.serializeParams(this.client_request),
        )
        .then((response) => {
          this.$emit('create');
          this.hide();
          this.$refs.successCreateModal.show();
        })
        .catch((err) => {
          this.backendErrors = err.response.data.errors;
        });
    },

    hide() {
      this.$refs.createModal.hide();
    },

    getBackendErrors(field) {
      if (this.backendErrors && this.backendErrors[field] && this.isSubmit)
        return Object.values(this.backendErrors[field]).join('. ');
    },
  },
};
</script>

<style></style>
