<template>
  <div>
    <BDropdownInput
      v-model="selected_id"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="tariff"
      :state="state"
      :debouncedTts="debouncedTts"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :minWordLength="minWordLength"
      compare-property="id"
      :display-property="'full_name'"
      comboBox
    />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput';

export default {
  name: 'TariffsAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    debouncedTts: {
      type: Number,
      default: 200,
    },
    placeholder: String,
    state: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    items: Array,
    value: {
      type: [Number, String],
      default: null,
    },
    minWordLength: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      selected_id: null,
      tariff: null,
    };
  },
  mounted() {
    if (this.value && this.value != null && this.value != undefined) {
      this.tariff = this.items.find((item) => item.id == this.value);
      this.selected_id = this.tariff.id;
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
    },
    onSelectItem(item) {
      this.tariff = item;
      this.$emit('select:item', item);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    fetch(val) {
      let tariffs = this.items;
      if (val) {
        tariffs = this.items.filter((item) => {
          return item.full_name.toLowerCase().includes(val.toLowerCase());
        });
      }
      return new Promise((resolve) => resolve(tariffs));
    },
  },
};
</script>
