<template>
  <div
    class="client-request"
    :class="`client-request_${request.status}`"
    @click="showModal"
  >
    <div
      class="client-request__img"
      :style="`background-image: url(${request.request_file_url});`"
    ></div>
    <div class="client-request__info">
      <div class="client-request__head">
        <div class="client-request__number">№{{ request.id }}</div>
        <div class="client-request__date">
          {{ $t('client_request.date_prefix') }} {{ date }}
        </div>
      </div>
      <div class="client-request__container">
        <div>
          <i :class="status" class="client-request__icon" />
        </div>
        <div class="client-request__wrapper">
          <div class="client-request__name">{{ request.name }}</div>
          <div class="client-request__id">{{ request.id }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'ClientRequest',
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date() {
      return dayjs(this.request.created_at).format('lll');
    },
    status() {
      if (this.request.status == 'new') {
        return 'icon-local-tree-end client-request__icon_clock';
      }
      if (this.request.status == 'in_progress') {
        return 'icon-local-tree-end client-request__icon_clock client-request__icon_in_progress';
      }
      if (
        this.request.status == 'denied' ||
        this.request.status == 'canceled'
      ) {
        return 'icon-local-times client-request__icon_canceled';
      }
      if (this.request.status == 'accepted') {
        return 'icon-local-ok client-request__icon_accepted';
      }
      return '';
    },
  },
  methods: {
    showModal() {
      this.$emit('openModal', this.request);
    },
  },
};
</script>

<style lang="scss"></style>
